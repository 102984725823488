import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import { NotificationContainerTypes } from "../../notificationContainerTypes";
import type { INotificationRepository } from "../repositories/INotificationRepository";

@injectable()
export class RemoveAllReadNotifications {
  @inject(NotificationContainerTypes.INotificationRepository)
  private repo!: INotificationRepository;

  async execute(): ResultFuture<boolean> {
    return this.repo.removeAllReadNotifications();
  }
}
