import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import { NotificationContainerTypes } from "../../notificationContainerTypes";
import type { INotificationRepository } from "../repositories/INotificationRepository";
import { DisabledNotificationsModel } from "../models/DisabledNotificationsModel";

@injectable()
export class GetDisabledNotifications {
  @inject(NotificationContainerTypes.INotificationRepository)
  private repo!: INotificationRepository;

  async execute(): ResultFuture<DisabledNotificationsModel> {
    return this.repo.getDisabledNotifications();
  }
}
