import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import { NotificationContainerTypes } from "../../notificationContainerTypes";
import type { INotificationRepository } from "../repositories/INotificationRepository";

@injectable()
export class ManageNotification {
  @inject(NotificationContainerTypes.INotificationRepository)
  private repo!: INotificationRepository;

  async execute(params: ManageNotificationParams): ResultFuture<boolean> {
    return this.repo.manageNotification(params);
  }
}

export class ManageNotificationParams {
  notificationTypeId: number;
  enable: boolean;

  constructor(notificationTypeId: number, enable: boolean) {
    this.notificationTypeId = notificationTypeId;
    this.enable = enable;
  }
}
