import { ContainerModule, interfaces } from "inversify";
import { INotificationRemoteDataSource } from "./data/dataSources/INotificationRemoteDataSource";
import { NotificationRemoteDataSource } from "./data/dataSources/NotificationRemoteDataSource";
import { NotificationContainerTypes } from "./notificationContainerTypes";
import { INotificationRepository } from "./domain/repositories/INotificationRepository";
import { NotificationRepository } from "./data/repositories/NotificationRepository";
import { GetDisabledNotifications } from "./domain/useCases/GetDisabledNotifications";
import { GetUnreadNotificationsCount } from "./domain/useCases/GetUnreadNotificationsCount";
import { ManageNotification } from "./domain/useCases/ManageNotification";
import { MarkAllNotificationsAsRead } from "./domain/useCases/MarkAllNotificationsAsRead";
import { MarkNotificationAsRead } from "./domain/useCases/MarkNotificationAsRead";
import { RemoveAllReadNotifications } from "./domain/useCases/RemoveAllReadNotifications";
import { RemoveNotification } from "./domain/useCases/RemoveNotification";
import { RemoveAllNotifications } from "./domain/useCases/RemoveAllNotifications";
import { GetNotifications } from "./domain/useCases/GetNotifications";

const notificationContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<INotificationRemoteDataSource>(NotificationContainerTypes.INotificationRemoteDataSource).to(
    NotificationRemoteDataSource
  );
  bind<INotificationRepository>(NotificationContainerTypes.INotificationRepository).to(NotificationRepository);
  bind<GetNotifications>(NotificationContainerTypes.GetNotifications).to(GetNotifications);
  bind<GetDisabledNotifications>(NotificationContainerTypes.GetDisabledNotifications).to(GetDisabledNotifications);
  bind<GetUnreadNotificationsCount>(NotificationContainerTypes.GetUnreadNotificationsCount).to(
    GetUnreadNotificationsCount
  );
  bind<ManageNotification>(NotificationContainerTypes.ManageNotification).to(ManageNotification);
  bind<MarkAllNotificationsAsRead>(NotificationContainerTypes.MarkAllNotificationsAsRead).to(
    MarkAllNotificationsAsRead
  );
  bind<MarkNotificationAsRead>(NotificationContainerTypes.MarkNotificationAsRead).to(MarkNotificationAsRead);
  bind<RemoveAllReadNotifications>(NotificationContainerTypes.RemoveAllReadNotifications).to(
    RemoveAllReadNotifications
  );
  bind<RemoveNotification>(NotificationContainerTypes.RemoveNotification).to(RemoveNotification);
  bind<RemoveAllNotifications>(NotificationContainerTypes.RemoveAllNotifications).to(RemoveAllNotifications);
});

export { notificationContainer };
