import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationModel } from "../../features/notification/domain/models/NotificationModel";
import { RootState } from "../index";
import { container } from "../../product/di_containers/inversify.config";
import { GetNotifications } from "../../features/notification/domain/useCases/GetNotifications";
import { NotificationContainerTypes } from "../../features/notification/notificationContainerTypes";
import { AppConst } from "../../product/constants/appConst";
import { RemoveNotification } from "../../features/notification/domain/useCases/RemoveNotification";
import { MarkNotificationAsRead } from "../../features/notification/domain/useCases/MarkNotificationAsRead";
import { MarkAllNotificationsAsRead } from "../../features/notification/domain/useCases/MarkAllNotificationsAsRead";
import { ManageNotification } from "../../features/notification/domain/useCases/ManageNotification";
import { GetDisabledNotifications } from "../../features/notification/domain/useCases/GetDisabledNotifications";
import { RemoveAllReadNotifications } from "../../features/notification/domain/useCases/RemoveAllReadNotifications";
import { RemoveAllNotifications } from "../../features/notification/domain/useCases/RemoveAllNotifications";
import {
  allNotificationsMarkedAsRead,
  allNotificationsRemoved,
  allReadNotificationsRemoved,
  disabledNotificationsLoaded,
  incrementPage,
  notificationManaged,
  notificationMarkedAsRead,
  notificationRemoved,
  resetNotifications,
  setIsCurrentLastPage,
  setUnreadNotificationsCount,
} from "./notificationSlice";
import { GetUnreadNotificationsCount } from "../../features/notification/domain/useCases/GetUnreadNotificationsCount";

export const fetchNotifications = createAsyncThunk<
  NotificationModel[],
  void,
  {
    rejectValue: string;
  }
>("notifications/fetchNotifications", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { currentPage, isCurrentLastPage } = state.notification;

  if (isCurrentLastPage) {
    return [];
  }

  const getNotificationsUseCase = container.get<GetNotifications>(NotificationContainerTypes.GetNotifications);
  const result = await getNotificationsUseCase.execute({ page: currentPage });

  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    (notifications) => {
      if (notifications.length < AppConst.defaultPageSize) {
        thunkAPI.dispatch(setIsCurrentLastPage(true));
      }
      thunkAPI.dispatch(incrementPage());
      return notifications;
    }
  );
});

export const refreshNotifications = createAsyncThunk<void, void, { rejectValue: string }>(
  "notifications/refreshNotifications",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(resetNotifications());
    thunkAPI.dispatch(fetchNotifications());
  }
);

export const removeNotification = createAsyncThunk<void, number, { rejectValue: string }>(
  "notifications/removeNotification",
  async (notificationId, thunkAPI) => {
    const removeNotificationUseCase = container.get<RemoveNotification>(NotificationContainerTypes.RemoveNotification);
    const result = await removeNotificationUseCase.execute(notificationId);

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {
        thunkAPI.dispatch(notificationRemoved(notificationId));
      }
    );
  }
);

export const markNotificationAsRead = createAsyncThunk<void, number, { rejectValue: string }>(
  "notifications/markNotificationAsRead",
  async (notificationId, thunkAPI) => {
    const markNotificationAsReadUseCase = container.get<MarkNotificationAsRead>(
      NotificationContainerTypes.MarkNotificationAsRead
    );
    const result = await markNotificationAsReadUseCase.execute(notificationId);

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {
        thunkAPI.dispatch(notificationMarkedAsRead(notificationId));
      }
    );
  }
);

export const markAllNotificationsAsRead = createAsyncThunk<void, void, { rejectValue: string }>(
  "notifications/markAllNotificationsAsRead",
  async (_, thunkAPI) => {
    const markAllNotificationsAsReadUseCase = container.get<MarkAllNotificationsAsRead>(
      NotificationContainerTypes.MarkAllNotificationsAsRead
    );
    const result = await markAllNotificationsAsReadUseCase.execute();

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {
        thunkAPI.dispatch(allNotificationsMarkedAsRead());
      }
    );
  }
);

export const manageNotification = createAsyncThunk<
  void,
  { notificationTypeId: number; shouldEnable: boolean },
  { rejectValue: string }
>("notifications/manageNotification", async ({ notificationTypeId, shouldEnable }, thunkAPI) => {
  const manageNotificationUseCase = container.get<ManageNotification>(NotificationContainerTypes.ManageNotification);
  const result = await manageNotificationUseCase.execute({ notificationTypeId, enable: shouldEnable });

  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    () => {
      thunkAPI.dispatch(notificationManaged({ notificationTypeId, shouldEnable }));
    }
  );
});

export const getDisabledNotifications = createAsyncThunk<number[], void, { rejectValue: string }>(
  "notifications/getDisabledNotifications",
  async (_, thunkAPI) => {
    const getDisabledNotificationsUseCase = container.get<GetDisabledNotifications>(
      NotificationContainerTypes.GetDisabledNotifications
    );
    const result = await getDisabledNotificationsUseCase.execute();

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      (disabledNotificationIds) => {
        thunkAPI.dispatch(disabledNotificationsLoaded(disabledNotificationIds));
        return disabledNotificationIds;
      }
    );
  }
);

export const removeAllReadNotifications = createAsyncThunk<void, void, { rejectValue: string }>(
  "notifications/removeAllReadNotifications",
  async (_, thunkAPI) => {
    const removeAllReadNotificationsUseCase = container.get<RemoveAllReadNotifications>(
      NotificationContainerTypes.RemoveAllReadNotifications
    );
    const result = await removeAllReadNotificationsUseCase.execute();

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {
        thunkAPI.dispatch(allReadNotificationsRemoved());
      }
    );
  }
);

export const removeAllNotifications = createAsyncThunk<void, void, { rejectValue: string }>(
  "notifications/removeAllNotifications",
  async (_, thunkAPI) => {
    const removeAllNotificationsUseCase = container.get<RemoveAllNotifications>(
      NotificationContainerTypes.RemoveAllNotifications
    );
    const result = await removeAllNotificationsUseCase.execute();

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {
        thunkAPI.dispatch(allNotificationsRemoved());
      }
    );
  }
);

export const getUnreadNotificationsCount = createAsyncThunk<void, void, { rejectValue: string }>(
  "notifications/getUnreadNotificationsCount",
  async (_, thunkAPI) => {
    const getNotificationsUseCase = container.get<GetUnreadNotificationsCount>(
      NotificationContainerTypes.GetUnreadNotificationsCount
    );
    const result = await getNotificationsUseCase.execute();

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      (count) => {
        thunkAPI.dispatch(setUnreadNotificationsCount(count));
      }
    );
  }
);
