import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import { NotificationContainerTypes } from "../../notificationContainerTypes";
import type { INotificationRepository } from "../repositories/INotificationRepository";
import { NotificationModel } from "../models/NotificationModel";
import { PaginationParams } from "../../../../product/params/paginationParams";

@injectable()
export class GetNotifications {
  @inject(NotificationContainerTypes.INotificationRepository)
  private repo!: INotificationRepository;

  async execute(params: PaginationParams): ResultFuture<NotificationModel[]> {
    return this.repo.getNotifications(params);
  }
}
