import React from "react";
import "./PopularTopicsBlock.css";

function exampleTopic() {
  return (
    <div className='popular-category-block'>
      <span>Gündem</span>
      <p>#istanbul</p>
      <span>2.400</span>
    </div>
  );
}

const PopularTopicsBlock: React.FC = () => {
  return (
    <div className='popular-topics-container'>
      <h3>Gündem</h3>
      {exampleTopic()}
      {exampleTopic()}
      {exampleTopic()}
      {exampleTopic()}
      {exampleTopic()}
    </div>
  );
};

export default PopularTopicsBlock;
